<template>
  <!-- Icons -->
  <div class="background">
    <v-main>
      <v-container>
        <v-row class="pt-8" justify="left">
          <v-col cols="12">
            <v-card rounded="50" class="my-10 pa-10">
              <v-card-title
                >Your plan has just been upgraded to a Growth Plan
                🎉</v-card-title
              >
              <v-card-text
                >From now you will benefit from unlimited size charts and
                unlimited product views. Thank you for trusting
                SmartSize!</v-card-text
              >
              <v-card-text
                >You can find more information about your Growth Plan
                <a
                  href="https://smartsize.zohodesk.eu/portal/en/kb/plans-pricing"
                  target="blank"
                >
                  here <v-icon small>mdi-open-in-new</v-icon></a
                >
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <SmartSizeFooter></SmartSizeFooter>
  </div>
</template>

<script>
import SmartSizeFooter from "@/components/GlobalComponents/SmartSizeFooter.vue";

export default {
  data() {
    return {};
  },
  components: {
    SmartSizeFooter,
  },
};
</script>

<style>
.background {
  background-color: rgb(255, 255, 255);
  min-height: 100vh;
}
</style>